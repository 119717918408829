import React from 'react'
import { Stack, Box } from '@mui/system'
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Typography } from '@mui/material'
import Insurances from './components/Insurances'
import { formatCurrencySymbol } from '../../../../../utils'

interface IMonthMetric {
  month: number;
  amount: number;
  aggregatedAmount: number;
}

export interface IInsuranceMetric {
  insurance: {
    id: number;
    name: string;
    provider: string;
  };
  amount: number;
  percentage: number;
}

interface IChartProps {
  perMonth: IMonthMetric[];
  perInsurance: IInsuranceMetric[];
}

const MONTH_LABELS: Record<number, string> = {
  1: 'Jan.',
  2: 'Fev.',
  3: 'Mars',
  4: 'Avr.',
  5: 'Mai',
  6: 'Juin',
  7: 'Juil.',
  8: 'Août',
  9: 'Sept.',
  10: 'Oct.',
  11: 'Nov.',
  12: 'Dec.',
}

const renderCustomAxisTick = ({ x, y, payload }: { x: number; y: number; payload: { value: number } }) => (
  <text x={x} y={y + 16} fill="#7A796E" textAnchor="middle" fontFamily="dm-sans" fontSize="12px">
    {MONTH_LABELS[payload.value] || ''}
  </text>
)

const getCustomBarPath = (x: number, y: number, width: number, height: number) => `
  M ${x + 4} ${y} 
  L ${x + (width - 4)} ${y} 
  Q ${x + width} ${y}, ${x + width} ${y + 4} 
  L ${x + width} ${y + height} 
  L ${x} ${y + height}
  L ${x} ${y + 4}
  Q ${x} ${y}, ${x + 4} ${y} 
Z`

const renderCustomBarShape = ({ fill, x, y, width, height }: any) => (
  <path d={getCustomBarPath(x, y, width, height)} stroke="none" fill={fill} />
)

const renderCustomLegend = () => (
  <ul>
    <li>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g opacity="0.5">
          <path
            d="M0 7.99998C0 7.72383 0.223858 7.49998 0.5 7.49998H15.5C15.7761 7.49998 16 7.72383 16 7.99998C16 8.27612 15.7761 8.49998 15.5 8.49998H0.5C0.223858 8.49998 0 8.27612 0 7.99998Z"
            fill="#3B97FF"
          />
          <path
            d="M7.12126 4.19231C7.51179 3.80178 8.14495 3.80178 8.53548 4.19231L11.4645 7.1213C11.855 7.51182 11.855 8.14499 11.4645 8.53551L8.53548 11.4645C8.14495 11.855 7.51179 11.855 7.12126 11.4645L4.19227 8.53551C3.80175 8.14499 3.80175 7.51182 4.19227 7.1213L7.12126 4.19231Z"
            fill="#3B97FF"
          />
        </g>
      </svg>
      <span>Commissions perçues cumulées</span>
    </li>
  </ul>
)

const CustomTooltip = ({ active, payload, label }: { active: boolean, payload: any[], label: string }) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const { aggregatedAmount = '' } = payload[2]?.payload ?? {};
  const { amount = '' } = payload[1]?.payload ?? {};

  const aggregatedAmountFormatted = formatCurrencySymbol(aggregatedAmount);
  const monthlyAmountFormatted = formatCurrencySymbol(amount);

  return (
    <div className="custom-tooltip">
      <p className="aggregatedAmount">Cumul: {aggregatedAmountFormatted}</p>
      <p className="amount">Mois: {monthlyAmountFormatted}</p>
    </div>
  );
};

function Charts({ perMonth, perInsurance }: IChartProps): React.ReactElement {
  return (
    <Stack direction="row" gap={3} mt={5} sx={{ alignItems: 'flex-start' }}>
      <Stack py="20px" width="66%">
        <Typography variant="h3">Évolution des commissions</Typography>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            mt: '12px',
            borderRadius: '4px',
            boxShadow:
              '0px 1px 5px 0px rgba(28, 47, 63, 0.08), 0px 3px 18px 0px rgba(28, 47, 63, 0.04), 0px 15px 80px 0px rgba(28, 47, 63, 0.03)',
          }}
        >
          <ResponsiveContainer width="100%" height={450}>
            <ComposedChart
              width={500}
              height={400}
              data={perMonth}
              margin={{
                top: 60,
                right: 20,
                bottom: 40,
                left: 40,
              }}
            >
              <defs>
                <linearGradient id="gradient-fill" x1="0" y1="0" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stopColor="#FFFFFF" />
                  <stop offset="1" stopColor="#A6D0FF" />
                </linearGradient>
              </defs>
              <Legend verticalAlign="top" align="left" wrapperStyle={{ top: 20 }} content={renderCustomLegend} />
              <CartesianGrid vertical={false} stroke="rgba(48, 129, 219, 0.50)" strokeDasharray="5 5" />
              <XAxis dataKey="month" tick={renderCustomAxisTick} />
              <YAxis orientation="right" tick={{fontFamily: 'dm-sans', fontSize: '12px'}} />
              <Tooltip content={<CustomTooltip active={false} payload={[]} label={''} />} />
              <Area type="monotone" dataKey="aggregatedAmount" fill="url(#gradient-fill)" legendType="none"
                    stroke="none" animationDuration={600} animationEasing="ease-in-out" />
              <Bar dataKey="amount" shape={renderCustomBarShape} barSize={50} fill="#3081DB"
                   background={{ fill: '#FBFBF9', opacity: 0 }} />
              <Line type="monotone" dataKey="aggregatedAmount" stroke="#3081DB" animationEasing="ease-in-out"
                    isAnimationActive={false} animationDuration={600} />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
      <Stack py="20px" width="33%">
        <Typography variant="h3">Commissions perçues par partenaires</Typography>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            mt: '12px',
            borderRadius: '4px',
            boxShadow:
              '0px 1px 5px 0px rgba(28, 47, 63, 0.08), 0px 3px 18px 0px rgba(28, 47, 63, 0.04), 0px 15px 80px 0px rgba(28, 47, 63, 0.03)',
          }}
        >
          <Insurances perInsurance={perInsurance} />
        </Box>
      </Stack>
    </Stack>
  )
}

export default Charts