import * as React from 'react'

import { Moment } from 'moment'
import { CalendarIcon, SearchIcon } from 'icons'

import {
  DateInput,
  SearchInput,
  Dropdown,
  Checkbox,
  BaseInput,
  Switch,
  ToggleButtonGroup,
} from 'component/Inputs'
import { IDropDownOption } from 'component/Inputs/Dropdown'

import {
  Box,
  Button,
  CheckboxProps,
  Chip,
  IconButton,
  SelectProps,
  Stack,
  ToggleButtonGroupProps,
  Typography,
} from '@mui/material'

import { FileUploader } from 'component'
import { IToggleButtonGroupButton } from 'component/Inputs/ToggleButtonGroup'

const OPTIONS: IDropDownOption[] = [
  {
    value: 'value1',
    display: 'Value 1',
  },
  {
    value: 'value2',
    display: 'Value 2',
  },
]

const BUTTONS: IToggleButtonGroupButton[] = [
  {
    display: '1',
    value: 1,
  },
  {
    display: '2',
    value: 2,
  },
  {
    display: '3',
    value: 3,
  },
]

const UI = () => {
  const [base, setBase] = React.useState('')
  const [baseSmall, setBaseSmall] = React.useState('')
  const [searchSmall, setSearchSmall] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [dropdownSmall, setDropdownSmall] = React.useState('')
  const [dropdown, setDropdown] = React.useState('')
  const [dateSmall, setDateSmall] = React.useState<Moment | null>(null)
  const [date, setDate] = React.useState<Moment | null>(null)
  const [checkboxSmallChecked, setCheckboxSmallChecked] = React.useState(false)
  const [checkboxChecked, setCheckboxChecked] = React.useState(false)
  const [switchSmallChecked, setSwitchSmallChecked] = React.useState(false)
  const [switchChecked, setSwitchChecked] = React.useState(false)
  const [file, setFile] = React.useState<File>()
  const [toggleButtonGroupSmall, setToggleButtonGroupSmall] = React.useState<number | string>()
  const [toggleButtonGroup, setToggleButtonGroup] = React.useState<number | string>()

  const handleTextChange = (
    setter: StateSetter<string>
  ): React.ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      setter(e.target.value)
    }
  }

  const handleBaseChange = (
    setter: StateSetter<string>
  ): React.ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      setter(e.target.value)
    }
  }

  const handleDropdownChange = (
    setter: StateSetter<string>
  ): NonNullable<SelectProps['onChange']> => {
    return (e) => {
      setter(e.target.value as string)
    }
  }

  const handleResetClick = (setter: StateSetter<string>): React.MouseEventHandler => {
    return () => {
      setter('')
    }
  }

  const handleCheckboxChange = (setter: StateSetter<boolean>): CheckboxProps['onChange'] => {
    return (_, checked) => {
      setter(checked)
    }
  }

  const handleToggleButtonGroupChange = (
    setter: StateSetter<number | string | undefined>
  ): ToggleButtonGroupProps['onChange'] => {
    return (e, value) => {
      setter(value)
    }
  }

  return (
    <Box sx={{ pt: 5, pb: 5, pl: 4, pr: 4 }}>
      <Typography sx={{ mb: 4 }} variant="h1">
        Inputs
      </Typography>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Base
        </Typography>
        <Stack sx={{ mb: 1 }} direction="row" justifyContent="flex-start">
          <BaseInput
            id="base-small"
            sx={{ mr: 1 }}
            label="small"
            value={baseSmall}
            onChange={handleBaseChange(setBaseSmall)}
            placeholder="placeholder"
            size="small"
            startIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
            endIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
          />
          <BaseInput
            id="base-small-disabled"
            sx={{ mr: 1 }}
            label="small - disabled"
            value={''}
            onChange={() => {}}
            placeholder="placeholder"
            size="small"
            disabled
            startIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
            endIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
          />
          <BaseInput
            id="base-small"
            sx={{ mr: 1 }}
            label="small"
            value={baseSmall}
            onChange={handleBaseChange(setBaseSmall)}
            placeholder="placeholder"
            size="small"
            color="error"
            startIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
            endIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
          />
          <BaseInput
            id="base-small-disabled"
            sx={{ mr: 1 }}
            color="error"
            label="small - disabled"
            value={''}
            onChange={() => {}}
            placeholder="placeholder"
            size="small"
            disabled
            endIcon={<CalendarIcon sx={{ color: 'text.secondary' }} />}
          />
        </Stack>
        <Stack sx={{ mb: 1 }} direction="row" justifyContent="flex-start">
          <BaseInput
            id="dropdown"
            sx={{ mr: 1 }}
            label="medium"
            value={base}
            onChange={handleBaseChange(setBase)}
            placeholder="placeholder"
          />
          <BaseInput
            id="dropdown-disabled"
            sx={{ mr: 1 }}
            label="medium - disabled"
            value={''}
            onChange={() => {}}
            placeholder="placeholder"
            disabled
            startIcon={<CalendarIcon />}
            endIcon={<CalendarIcon />}
          />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          DropDown
        </Typography>
        <Stack sx={{ mb: 1 }} direction="row" justifyContent="flex-start">
          <Dropdown
            id="dropdown-small"
            sx={{ mr: 1 }}
            label="small"
            value={dropdownSmall}
            onChange={handleDropdownChange(setDropdownSmall)}
            placeholder="placeholder"
            options={OPTIONS}
            size="small"
          />
          <Dropdown
            id="dropdown-small-disabled"
            sx={{ mr: 1 }}
            label="small - disabled"
            value={''}
            onChange={() => {}}
            placeholder="placeholder"
            options={OPTIONS}
            size="small"
            disabled
          />
        </Stack>
        <Stack sx={{ mb: 1 }} direction="row" justifyContent="flex-start">
          <Dropdown
            id="dropdown"
            sx={{ mr: 1 }}
            label="medium"
            value={dropdown}
            onChange={handleDropdownChange(setDropdown)}
            placeholder="placeholder"
            options={OPTIONS}
          />
          <Dropdown
            id="dropdown-disabled"
            sx={{ mr: 1 }}
            label="medium - disabled"
            value={''}
            onChange={() => {}}
            placeholder="placeholder"
            options={OPTIONS}
            disabled
          />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Search
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <SearchInput
            id="search-small"
            sx={{ mr: 1 }}
            label="small"
            value={searchSmall}
            onChange={handleTextChange(setSearchSmall)}
            onReset={handleResetClick(setSearchSmall)}
            size="small"
          />
          <SearchInput
            id="search-small-disabled"
            label="small - disabled"
            value=""
            onChange={() => {}}
            onReset={() => {}}
            size="small"
            disabled
          />
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <SearchInput
            id="search"
            sx={{ mr: 1 }}
            label="medium"
            value={search}
            onChange={handleTextChange(setSearch)}
            onReset={handleResetClick(setSearch)}
          />
          <SearchInput
            id="search-disabled"
            label="medium - disabled"
            value=""
            onChange={() => {}}
            onReset={() => {}}
            disabled
          />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          DatePicker
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <DateInput
            id="date"
            sx={{ mr: 1 }}
            label="small"
            value={dateSmall}
            onChange={(value) => setDateSmall(value)}
            placeholder="placeholder"
            size="small"
          />
          <DateInput
            id="search-small-disabled"
            label="small - disabled"
            value={null}
            onChange={() => {}}
            placeholder="placeholder"
            disabled
            size="small"
          />
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <DateInput
            id="date"
            sx={{ mr: 1 }}
            label="medium"
            value={date}
            onChange={(value) => setDate(value)}
            placeholder="placeholder"
          />
          <DateInput
            id="search-small-disabled"
            label="medium - disabled"
            value={null}
            onChange={() => {}}
            placeholder="placeholder"
            disabled
            views={['month', 'year']}
            openTo="month"
          />
          <DateInput
            id="search-small"
            value={date}
            onChange={(value) => setDate(value)}
            label="medium - disabled"
            placeholder="placeholder"
            openTo="month"
            views={['month', 'year']}
            disableFuture
          />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Checkbox
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Checkbox
            sx={{ mr: 1 }}
            label="small"
            checked={checkboxSmallChecked}
            onChange={handleCheckboxChange(setCheckboxSmallChecked)}
            size="small"
          />
          <Checkbox sx={{ mr: 1 }} label="small - disabled" checked={false} disabled size="small" />
          <Checkbox label="small - indeterminate" checked indeterminate size="small" />
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Checkbox
            sx={{ mr: 1 }}
            label="medium"
            checked={checkboxChecked}
            onChange={handleCheckboxChange(setCheckboxChecked)}
          />
          <Checkbox sx={{ mr: 1 }} label="medium - disabled" checked={false} disabled />
          <Checkbox label="medium - indeterminate" checked indeterminate />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Switch
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Switch
            sx={{ mr: 1 }}
            label="small"
            checked={switchSmallChecked}
            onChange={(e) => {
              setSwitchSmallChecked(e.target.checked)
            }}
            size="small"
          />
          <Switch label="small - disabled" checked={false} disabled size="small" />
        </Stack>
        <Stack direction="row">
          <Switch
            sx={{ mr: 1 }}
            label="medium"
            checked={switchChecked}
            onChange={(e) => {
              setSwitchChecked(e.target.checked)
            }}
          />
          <Switch label="medium - disabled" checked={false} disabled />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Toggle Button Group
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <ToggleButtonGroup
            sx={{ mr: 1 }}
            label="small"
            exclusive
            buttons={BUTTONS}
            value={toggleButtonGroupSmall}
            onChange={handleToggleButtonGroupChange(setToggleButtonGroupSmall)}
            size="small"
          />
        </Stack>
        <Stack direction="row">
          <ToggleButtonGroup
            sx={{ mr: 1 }}
            label="medium"
            exclusive
            buttons={BUTTONS}
            value={toggleButtonGroup}
            onChange={handleToggleButtonGroupChange(setToggleButtonGroup)}
          />
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Buttons
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="primary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            primary - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="primary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            primary - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color="primary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            primary - medium
          </Button>
          <Button
            sx={{ mr: 1 }}
            color="primary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            primary - medium - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            secondary - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            secondary - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color="secondary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            secondary - medium
          </Button>
          <Button
            sx={{ mr: 1 }}
            color="secondary"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            secondary - medium - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="error"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            error - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="error"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            error - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color="error"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            error - medium
          </Button>
          <Button
            sx={{ mr: 1 }}
            color="error"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            error - medium - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="neutralLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            neutral (text) - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="neutralLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            neutral (text) - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="medium"
            color="neutralLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            neutral (text) - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="medium"
            color="neutralLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            neutral (text) - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="errorLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            neutral (text) - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="errorLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            neutral (text) - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="medium"
            color="errorLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            neutral (text) - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="medium"
            color="errorLight"
            variant="contained"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            neutral (text) - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="neutralLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            neutral (text) - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="neutralLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            neutral (text) - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color="neutralLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            neutral (text) - medium
          </Button>
          <Button
            sx={{ mr: 1 }}
            color="neutralLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            neutral (text) - medium - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="errorLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            error (text) - small
          </Button>
          <Button
            sx={{ mr: 1 }}
            size="small"
            color="errorLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            error (text) - small - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Button
            sx={{ mr: 1 }}
            color="errorLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
          >
            error (text) - medium
          </Button>
          <Button
            sx={{ mr: 1 }}
            color="errorLight"
            variant="text"
            startIcon={<SearchIcon />}
            endIcon={<CalendarIcon />}
            disabled
          >
            error (text) - medium - disabled
          </Button>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <IconButton sx={{ mr: 1 }} size="small" color="primary">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="primary" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="secondary">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="secondary" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="neutral">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="neutral" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="neutralLight">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="neutralLight" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="errorLight">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} size="small" color="errorLight" disabled>
            <CalendarIcon />
          </IconButton>
        </Stack>
        <Stack direction="row" sx={{ mb: 1 }}>
          <IconButton sx={{ mr: 1 }} color="primary">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="primary" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="secondary">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="secondary" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="neutral">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="neutral" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="neutralLight">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="neutralLight" disabled>
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="errorLight">
            <CalendarIcon />
          </IconButton>
          <IconButton sx={{ mr: 1 }} color="errorLight" disabled>
            <CalendarIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          File Uploader
        </Typography>
        <Box sx={{ width: '500px' }}>
          <FileUploader
            formats={['pdf']}
            sizeLimit="20mb"
            onChange={(file) => {
              setFile(file)
            }}
            onReset={() => {
              setFile(undefined)
            }}
            file={file}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h2">
          Chips
        </Typography>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Chip sx={{ mr: 1 }} size="small" label="Success" color="success" />
          <Chip sx={{ mr: 1 }} size="small" label="Error" color="error" />
          <Chip sx={{ mr: 1 }} size="small" label="Warning" color="warning" />
          <Chip sx={{ mr: 1 }} size="small" label="Info" color="info" />
          <Chip sx={{ mr: 1 }} size="small" label="Neutral" color="default" />
          <Chip sx={{ mr: 1 }} size="small" variant="outlined" label="Success" color="success" />
          <Chip sx={{ mr: 1 }} size="small" variant="outlined" label="Error" color="error" />
          <Chip sx={{ mr: 1 }} size="small" variant="outlined" label="Warning" color="warning" />
          <Chip sx={{ mr: 1 }} size="small" variant="outlined" label="Info" color="info" />
          <Chip sx={{ mr: 1 }} size="small" variant="outlined" label="Neutral" color="default" />
          <Chip sx={{ mr: 1 }} size="small" variant="tag" label="Tag" />
          <Chip sx={{ mr: 1 }} size="small" variant="tag" label="Tag" className="active" />
        </Stack>
        <Stack direction="row">
          <Chip sx={{ mr: 1 }} label="Success" color="success" />
          <Chip sx={{ mr: 1 }} label="Error" color="error" />
          <Chip sx={{ mr: 1 }} label="Warning" color="warning" />
          <Chip sx={{ mr: 1 }} label="Info" color="info" />
          <Chip sx={{ mr: 1 }} label="Neutral" color="default" />
          <Chip sx={{ mr: 1 }} variant="outlined" label="Success" color="success" />
          <Chip sx={{ mr: 1 }} variant="outlined" label="Error" color="error" />
          <Chip sx={{ mr: 1 }} variant="outlined" label="Warning" color="warning" />
          <Chip sx={{ mr: 1 }} variant="outlined" label="Info" color="info" />
          <Chip sx={{ mr: 1 }} variant="outlined" label="Neutral" color="default" />
          <Chip sx={{ mr: 1 }} variant="tag" label="Tag" />
          <Chip sx={{ mr: 1 }} variant="tag" label="Tag" className="active" />
        </Stack>
      </Box>
    </Box>
  )
}

export default UI
