import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import EventEmitter, { UPDATE_EVENT_CLIENTS } from 'utils/events'
import {
  FROZEN_ASSETS_ENTITIES_CLIENTS_URL,
} from 'utils/router/constants'

import { getDBUploadState, uploadClientDB } from './modalUploadClients.service'

import { DialogProps } from '@mui/material'

export const handleFileChange = (
  setFile: StateSetter<File | undefined>,
  setCrm: StateSetter<ICrmType| undefined>,
  crm: ICrmType
): React.ChangeEventHandler<HTMLInputElement> => {
  return (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    setFile(e.target.files[0])
    setCrm(crm)
  }
}

export const handleFileDelete = (
  setFile: StateSetter<File | undefined>,
  setCrm: StateSetter<ICrmType | undefined>
): React.MouseEventHandler => {
  return () => {
    setFile(undefined)
    setCrm(undefined)
  }
}

export const handleLoadFilesClick = (
  ref: React.RefObject<HTMLInputElement>
): React.MouseEventHandler => {
  return () => {
    if (ref.current === null) {
      return
    }

    ref.current.click()
  }
}

export const handleImportClick = ({
  file,
  crm,
  setProgress,
  setError,
  setStep,
}: {
  crm: ICrmType | undefined
  file: File | undefined
  setProgress: StateSetter<number>
  setError: StateSetter<string | undefined>
  setStep: StateSetter<number>
}) => {
  return async () => {
    setProgress(0)
    setError(undefined)
    setStep((step) => step + 1)

    if (!file) {
      setError('Selectionnez un fichier à importer')
      return
    }

    if (!crm) {
      setError('Selectionnez un crm')
      return
    }

    const jobId: any = await uploadClientDB({ file, crm })

    if (!jobId) {
      return
    }

    const interval = setInterval(() => {
      getDBUploadState({
        jobId,
        interval,
        setProgress,
        setError,
        setStep,
      })
    }, 1000)
  }
}

export const handleSeeClientsClick = ({
  history,
  onClose,
  setError,
  setProgress,
  setFile,
  setStep,
  setCrm,
}: {
  history: RouteComponentProps['history']
  onClose: NonNullable<DialogProps['onClose']>
  setError: StateSetter<string | undefined>
  setProgress: StateSetter<number>
  setFile: StateSetter<File | undefined>
  setStep: StateSetter<number>
  setCrm: StateSetter<ICrmType | undefined>
}): React.MouseEventHandler => {
  return () => {
    history.push(FROZEN_ASSETS_ENTITIES_CLIENTS_URL)
    EventEmitter.dispatch(UPDATE_EVENT_CLIENTS)
    setError(undefined)
    setProgress(0)
    setFile(undefined)
    setStep(1)
    setCrm(undefined)
    onClose({}, 'backdropClick')
  }
}

export const getModalTitle = (step: number): string | undefined => {
  switch (step) {
    case 1:
      return 'Importer les fichiers de vos CRM'
    case 2:
      return 'Importation en cours'
    case 3:
      return 'Confirmation'
    default:
      console.log('unknown modal step')
  }
}

export const handleRetryClick = ({
  setError,
  setProgress,
  setFile,
  setStep,
  setCrm,
}: {
  setError: StateSetter<string | undefined>
  setProgress: StateSetter<number>
  setFile: StateSetter<File | undefined>
  setStep: StateSetter<number>
  setCrm: StateSetter<ICrmType | undefined>
}): React.MouseEventHandler => {
  return () => {
    setError(undefined)
    setProgress(0)
    setFile(undefined)
    setStep(1)
    setCrm(undefined)
  }
}

export const handleModalClose = ({
  onClose,
  setError,
  setProgress,
  setFile,
  setStep,
  setCrm,
}: {
  onClose: NonNullable<DialogProps['onClose']>
  setError: StateSetter<string | undefined>
  setProgress: StateSetter<number>
  setFile: StateSetter<File | undefined>
  setStep: StateSetter<number>
  setCrm: StateSetter<ICrmType | undefined>
}): React.MouseEventHandler => {
  return () => {
    onClose({}, 'backdropClick')
    setError(undefined)
    setProgress(0)
    setFile(undefined)
    setStep(1)
    setCrm(undefined)
  }
}

export const handleModalCloseClick = ({
  onClose,
}: {
  onClose: NonNullable<DialogProps['onClose']>
}): React.MouseEventHandler => {
  return () => {
    onClose({}, 'backdropClick')
  }
}
