export const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const API_URL = `${BASE_URL}/v1/`
export const ADMIN_ACTION = process.env.REACT_APP_ADMIN_ACTION

export const API_ARBITRAGE_FEE_URL = `${API_URL}insurance-fee-arbitrage`
export const API_CLIENTS_URL = `${API_URL}clients`
export const API_CONTRACTS_URL = `${API_URL}contracts`
export const API_CRUMB_URL = `${API_URL}crumb`
export const API_DEPOSIT_FEE_URL = `${API_URL}insurance-fee-deposit`
export const API_DOC = `${API_URL}documents`
export const API_DOCKETS_URL = `${API_URL}insurance-fee-reports`
export const API_FILE_TRANSFER_URL = `${API_URL}filetransfer`
export const API_FROZEN_ASSET_ENTITIES_URL = `${API_URL}frozen-asset-entities`
export const API_FROZEN_ASSET_CHECK_PROOFS_URL = `${API_URL}frozen-asset-check-proofs`
export const API_INSURANCE_PRODUCT_URL = `${API_URL}insurance-products`
export const API_LOGIN_URL = `${API_URL}login`
export const API_LOGOUT_URL = `${API_URL}logout`
export const API_ME_URL = `${API_URL}me`
export const API_MS_DOC = `${API_URL}ms-doc`
export const API_ORGANIZATION_URL = `${API_URL}organization`
export const API_PASSWORD_URL = `${API_URL}password`
export const API_PDF_URL = `${API_URL}pdf`
export const API_QUEUE_URL = `${API_URL}queue`
export const API_REGISTRATION_URL = `${API_URL}registrations`
export const API_REPORTING_CLIENTS_URL = `${API_URL}reporting-clients`
export const API_REPORTING_URL = `${API_URL}customer-fee-reports`
export const API_SEARCH_TAGS = `${API_URL}typeahead?types=tags&search=`
export const API_UCITS_FEE_URL = `${API_URL}insurance-fee-ucits`
export const API_UPFRONT_FEE_URL = `${API_URL}insurance-fee-upfront`
export const API_UPLOAD_URL = `${API_URL}upload`
export const API_USER_INSURANCE_URL = `${API_URL}insurances`
export const API_USERS_URL = `${API_URL}users`
export const API_INSURANCE_FEE_METRICS_URL = `${API_URL}insurance-fee-metrics`
