import * as React from 'react'

import { ArrowLeftIcon } from 'icons'
import { HOMEPAGE_URL } from 'utils/router/constants'

import { getLocationTitle, getLocationSubtitle, getLocationNav, getHideableElementSx } from '../../core/Menu.helpers'
import { NavMenuItem } from '../../components'

import { Box, Stack, Typography, useTheme } from '@mui/material'

interface IProps {
  location: 'reportings' | 'asset-freeze' | 'preferences'
  open: boolean
}

const DefaultMenu: React.FC<IProps> = (props) => {
  const { location, open } = props

  const theme = useTheme()
  const hideableElementSx = getHideableElementSx({ theme, open })

  return (
    <Stack>
      <NavMenuItem
        icon={ArrowLeftIcon}
        name=""
        link={HOMEPAGE_URL}
        menuOpen={open}
        sx={{ flexGrow: 1 }}
        exact={true}
      />
      <Stack gap={2}>
        <Box px={3}>
          <Typography
            variant="h1"
            fontWeight={500}
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
              ...(hideableElementSx as object),
            })}
          >
            {getLocationTitle(location)}
          </Typography>
            <Stack sx={(theme) => ({
              ...(hideableElementSx as object),
            })}>
            <Typography
              variant="body2"
              fontWeight={400}
              sx={(theme) => ({
                color: theme.palette.secondary.contrastText,
                whiteSpace: 'wrap',
                width: '210px',
              })}
              dangerouslySetInnerHTML={{ __html: getLocationSubtitle(location) }}
            >
            </Typography>
          </Stack>
        </Box>
        <Stack gap={'32px'} mt={'30px'}>
          {getLocationNav(location).map((nav, index) => (
            <Stack key={index}>
              {nav.label !== "" && (
                <Typography
                  variant="sectionHeading"
                  fontWeight={700}
                  letterSpacing={2}
                  px={3}
                  sx={(theme) => ({
                    color: theme.palette.secondary.contrastText,
                    mb: '10px',
                    ...(hideableElementSx as object),
                  })}
                >
                  {nav.label}
                </Typography>
              )}
              {nav.fields.map((item) => (
                <NavMenuItem
                  key={item.name}
                  icon={item.icon}
                  name={item.name}
                  link={item.link}
                  external={item.externalLink}
                  menuOpen={open}
                  exact={item.exact ? item.exact : false}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DefaultMenu
