import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { API_INSURANCE_FEE_METRICS_URL } from '../../../api/constants'
import { customFetch } from '../../../api/customFetch'
import { LayoutMenu } from 'component/Layouts'
import Summary from './components/Summary'
import Guides from './components/Guides'
import Charts from './components/Charts'

interface IFeeMetrics {
  availableYears: number[];
  year: number | undefined;
  aggregated: {
    run: number;
    operations: number;
    totalAmount: number;
  };
  perInsurance: [];
  perMonth: [];
}

interface IUserState {
  firstName: string;
  lastName: string;
}

const INITIAL_FEE_METRICS: IFeeMetrics = {
  aggregated: { operations: 0, run: 0, totalAmount: 0 },
  availableYears: [],
  perInsurance: [],
  perMonth: [],
  year: undefined,
}

const FETCH_HEADERS = {
  credentials: 'include' as RequestCredentials,
  headers: {
    'x-csrf-token': customFetch.getCSRFToken(),
  },
  method: 'GET',
}

function Ace(): React.ReactElement {
  const [feeMetrics, setFeeMetrics] = useState<IFeeMetrics>(INITIAL_FEE_METRICS)
  const [year, setYear] = useState<number | undefined>(undefined)

  const userState = useSelector((state: { user: IUserState }) => state.user)

  const userName = useMemo(() => `${userState?.firstName} ${userState?.lastName}`, [userState])

  const fetchMetrics = useCallback(async (year?: number) => {
    try {
      const url = new URL(API_INSURANCE_FEE_METRICS_URL)
      if (year) {
        url.searchParams.append('year', year.toString())
      }
      const response = await fetch(url.toString(), FETCH_HEADERS)
      if (response.ok) {
        const data: IFeeMetrics = await response.json()
        setFeeMetrics(data)
      }
    } catch (error) {
      console.error('Failed to fetch insurance fee metrics:', error)
    }
  }, [])

  const handleYearFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newYear = parseInt(e.target.value)
      setYear(newYear)
    },
    [],
  )

  useEffect(() => {
    fetchMetrics(year)
  }, [year, fetchMetrics])

  return (
    <LayoutMenu location="reportings" defaultMenuOpen={false} canClose={true}>
      <Box sx={{ py: '48px', px: '32px' }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h1" sx={{letterSpacing: '-2px'}}>Bonjour {userName},</Typography>
        </Stack>
        <Summary
          availableYears={feeMetrics.availableYears}
          year={year ? year : feeMetrics.year}
          handleFilter={handleYearFilterChange}
          aggregated={feeMetrics.aggregated}
        />
        <Charts perMonth={feeMetrics.perMonth} perInsurance={feeMetrics.perInsurance} />
        <Guides />
      </Box>
    </LayoutMenu>
  )
}

export default Ace