import * as React from 'react'

import { FROZEN_ASSETS_ENTITIES_CLIENTS_URL, REPORTING_ACE } from 'utils/router/constants'

import { FeatureMenuItem } from '../../components'

import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'

interface IProps {
  user: any
  open: boolean
}

interface IFeature {
  title: string
  description: string
  link: string
  disabled: boolean
  soon: boolean
}

const HomeMenu: React.FC<IProps> = (props) => {
  const { user } = props

  const FEATURES: IFeature[] = [
    {
      title: 'STASE',
      description: 'Contrôle continu du gel des avoirs',
      link: FROZEN_ASSETS_ENTITIES_CLIENTS_URL,
      disabled: !user.organization.enableFrozenAssets,
      soon: false,
    },
    {
      title: 'ACE',
      description: 'Agrégation et analyse de vos commissions <strong>ex-post</strong>',
      link: REPORTING_ACE,
      disabled: !user.organization.enableReports,
      soon: true,
    },
  ]

  return (
    <Stack gap={2} pl={3} pr={3}>
      <Typography variant="body2" fontWeight={500} color="primaryLight.main">
        Bienvenue,
      </Typography>
      {FEATURES.map((feature) => (
        <FeatureMenuItem
          key={feature.title}
          title={feature.title}
          description={feature.description}
          link={feature.link}
          disabled={feature.disabled}
          soon={feature.soon}
        />
      ))}
    </Stack>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(HomeMenu)
