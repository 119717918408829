import * as React from 'react'
import moment from 'moment'

import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'utils'
import EventEmitter, { UPDATE_EVENT_CLIENTS } from 'utils/events'

import { Pagination } from 'component'
import { CardDoublePanel } from 'component/Cards'
import { TypographyCell, ClientStatusCell } from 'component/Layouts/TableCells'
import { SearchInput } from 'component/Inputs'
import { BaseTable } from 'component/Tables'

import { Box, Stack, Typography, styled } from '@mui/material'
import { DataGridProProcessedProps } from '@mui/x-data-grid-pro/models/dataGridProProps'
import { GridActionsCellItem, GridRowParams, GridRowsProp } from '@mui/x-data-grid-premium'

import ClientDetail from '../ClientDetail'
import SuspectDetail from '../SuspectDetail'
import {
  handleModalTriggerClick,
  handleSearchChange,
  handleSearchReset,
  handleSeeFaeDetailsClick,
  updateAllData,
  updateURL,
} from '../../core/Clients.helpers'
import SuspectStat from '../SuspectStat'
import SuspectAction from '../SuspectAction'
import ClientStat from '../ClientStat'
import ClientAction from '../ClientAction'
import NoRowsOverlay from '../NoRowsOverlay'
import { ModalFrozenAssetEntity, ModalUploadClients } from 'component/Modals'
import { IInitialClientsValue } from '../..'
import { GridColDef } from '@mui/x-data-grid-premium'
import { API_CLIENTS_URL } from 'api/constants'
import { DownloadIcon } from 'icons'

export type IClientRowStatus = 'suspected' | 'no-frozen-asset'

export interface IClientRowFae {
  name: string
  firstNames: string[] | null
  titles: string[] | null
  aliases: string[] | null
  genders: string[] | null
  datesOfBirth: string[] | null
  placesOfBirth: string[] | null
  reasons: string[] | null
  legalGrounds: string[] | null
  UEReferences: string[] | null
}

export interface IClientRow {
  id: number
  clientType: string
  lastName: string
  firstName: string | null
  maidenName: string | null
  dateOfBirth: string | null
  placeOfBirth: string | null
  countryOfBirth: string | null
  status: IClientRowStatus
  lastCheck: string
  prismeId: number | null
  o2sId: string | null
  uniqueId: string | null
  nexusId: number | null
  nationality: string | null
  otherNationality: string | null
  occupation: string | null
  email: string | null
  phone: string | null
  mobilePhone: string | null
  address: string | null
  frozenAssetEntity: IClientRowFae | null
}

interface IClientsListLayout {
  initialValues: IInitialClientsValue | undefined
  initalLoading: boolean
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void
  suspectCount: number | undefined
  setSuspectCount: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ClientsListLayout = ({
  initialValues,
  initalLoading,
  handleTabChange,
  suspectCount,
  setSuspectCount,
}: IClientsListLayout) => {
  const history = useHistory()

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = React.useState(initalLoading)
  const [headerLoading, setHeaderLoading] = React.useState(initalLoading)
  const [search, setSearch] = React.useState(searchParams.get('r') || '')
  const [clientCount, setClientCount] = React.useState<number>()
  const [filteredClientCount, setFilteredClientCount] = React.useState<number>()
  const [rows, setRows] = React.useState<GridRowsProp<IClientRow>>()
  const [faeModalOpen, setFaeModalOpen] = React.useState(false)
  const [clientModalOpen, setClientModalOpen] = React.useState(false)
  const [selectedFae, setSelectedFae] = React.useState<IClientRowFae>()
  const [clientDBUpdate, setClientDBUpdate] = React.useState<string>()
  const [faeDBUpdate, setFaeDBUpdate] = React.useState<string>()

  const debouncedSearch = useDebounce(search, 800)

  const COLUMNS: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'lastName',
        headerName: 'Nom',
        flex: 1,
        sortable: false,
        renderCell(params) {
          return (
            <TypographyCell
              variant="subtitle1"
              fontWeight={500}
              color="text.primary"
              textTransform="capitalize"
            >
              {params.value}
            </TypographyCell>
          )
        },
      },
      {
        field: 'firstName',
        headerName: 'Prénom',
        flex: 1,
        sortable: false,
        renderCell(params) {
          return (
            <TypographyCell variant="subtitle1" color="text.primary" textTransform="capitalize">
              {params.value || '-'}
            </TypographyCell>
          )
        },
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date de naissance',
        flex: 1,
        sortable: false,
        renderCell(params) {
          return (
            <TypographyCell
              variant="subtitle1"
              color="text.secondary"
              className="MuiDataGrid-cell--date-of-birth"
            >
              {params.value || '-'}
            </TypographyCell>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Statut',
        flex: 1,
        sortable: false,
        renderCell(params) {
          return <ClientStatusCell status={params.value} />
        },
      },
      {
        field: 'lastCheck',
        headerName: 'Date de vérification',
        width: 264,
        sortable: false,
        renderCell(params) {
          return (
            <TypographyCell
              variant="subtitle1"
              color="text.secondary"
              className="MuiDataGrid-cell--last-check"
            >
              {params.value}
            </TypographyCell>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 48,
        getActions: (params: GridRowParams<IClientRow>) =>
          params.row.frozenAssetEntity !== null
            ? []
            : [
                // @ts-ignore
                <GridActionsCellItem
                  icon={
                    <a
                      href={`${API_CLIENTS_URL}/${params.row.id}?getPdf=true`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadIcon sx={{ color: 'text.secondary' }} />
                    </a>
                  }
                  label="download"
                  onClick={() => null}
                ></GridActionsCellItem>,
              ],
      },
    ],
    []
  )

  const getCrmId = (row: IClientRow): string | null => {
    if (row.prismeId) {
      return row.prismeId.toString()
    } else if (row.o2sId) {
      return row.o2sId
    } else if (row.uniqueId) {
      return row.uniqueId
    } else if (row.nexusId) {
      return row.nexusId.toString()
    }

    return null
  };

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProcessedProps<IClientRow>['getDetailPanelContent']>
  >(
    ({ row }) => (
      <Stack flexDirection="row" gap={2} justifyContent="space-between">
        <ClientDetail
          crmId={getCrmId(row)}
          clientType={row.clientType}
          maidenName={row.maidenName}
          placeOfBirth={row.placeOfBirth}
          countryOfBirth={row.countryOfBirth}
          nationality={row.nationality}
          otherNationality={row.otherNationality}
          occupation={row.occupation}
          address={row.address}
        />
        {row.frozenAssetEntity ? (
          <SuspectDetail
            name={row.frozenAssetEntity.name}
            firstNames={row.frozenAssetEntity.firstNames}
            titles={row.frozenAssetEntity.titles}
            onSeeDetailsClick={handleSeeFaeDetailsClick({
              setFaeModalOpen,
              setSelectedFae,
              fae: row.frozenAssetEntity,
            })}
          />
        ) : (
          <Box sx={{ width: 280 }}></Box>
        )}
      </Stack>
    ),
    []
  )

  const getDetailPanelHeight = React.useCallback((): 'auto' => 'auto', [])

  const firstRender = React.useRef(true)

  React.useEffect(() => {
    if (initialValues) {
      setRows(initialValues.rows)
      setClientCount(initialValues.clientCount)
      setFilteredClientCount(initialValues.filteredClientCount)
      setClientDBUpdate(initialValues.clientDBUpdate)
      setFaeDBUpdate(initialValues.faeDBUpdate)
      setLoading(false)
      setHeaderLoading(false)
      firstRender.current = false
    }
  }, [initialValues])

  React.useEffect(() => {
    updateURL({ debouncedSearch, history })
  }, [debouncedSearch, history])

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_CLIENTS, () => {
      updateAllData({
        setLoading,
        setHeaderLoading: firstRender.current ? setHeaderLoading : undefined,
        debouncedSearch,
        page,
        setClientCount,
        setFilteredClientCount,
        setSuspectCount,
        setClientDBUpdate,
        setFaeDBUpdate,
        setRows,
      })
    })

    if (!firstRender.current) {
      updateAllData({
        setLoading,
        setHeaderLoading: firstRender.current ? setHeaderLoading : undefined,
        debouncedSearch,
        page,
        setClientCount,
        setFilteredClientCount,
        setSuspectCount,
        setClientDBUpdate,
        setFaeDBUpdate,
        setRows,
      })
    }

    return () => EventEmitter.remove(UPDATE_EVENT_CLIENTS)
  }, [debouncedSearch, page, setSuspectCount])

  return (
    <>
      <Summary flexDirection="row" justifyContent="space-between" alignItems="center" mb={3}>
        <CardDoublePanel
          leftPanel={<SuspectStat stat={suspectCount} loading={headerLoading} />}
          rightPanel={
            <SuspectAction
              dbUpdateDate={moment(faeDBUpdate).format('D MMMM YYYY')}
              dbUpdateDelta={moment().to(faeDBUpdate)}
              loading={headerLoading}
              disabled={rows ? rows.length === 0 : true}
              handleTabChange={handleTabChange}
            />
          }
        />
        <CardDoublePanel
          leftPanel={<ClientStat stat={clientCount} loading={headerLoading} />}
          rightPanel={
            <ClientAction
              dbUpdateDate={moment(clientDBUpdate).format('D MMMM YYYY')}
              dbUpdateDelta={moment().to(clientDBUpdate)}
              loading={headerLoading}
              disabled={rows ? rows.length === 0 : true}
            />
          }
        />
      </Summary>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={1.5}>
        <SearchInput
          id="search"
          value={search}
          onChange={handleSearchChange(setSearch)}
          onReset={handleSearchReset(setSearch)}
        />
        {filteredClientCount ? (
          <Stack direction="row" alignItems="flex-end">
            <Typography
              variant="h3"
              fontWeight={500}
              sx={{ mr: 0.5 }}
              color="text.primary"
              lineHeight="23px"
            >
              {filteredClientCount}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              ligne{filteredClientCount > 1 ? 's' : null}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
      <Table
        autoHeight
        disableVirtualization
        columns={COLUMNS}
        rows={rows || []}
        loading={loading}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              debouncedSearch={debouncedSearch}
              onImportClick={handleModalTriggerClick(setClientModalOpen, true)}
            />
          ),
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        sx={{ '--DataGrid-overlayHeight': '300px' }}
      />
      {filteredClientCount ? (
        <Stack justifyContent="center" alignItems="center" mt={2}>
          <Pagination page={page} setPage={setPage} pageSize={50} total={filteredClientCount} />
        </Stack>
      ) : null}

      <ModalFrozenAssetEntity
        open={faeModalOpen}
        onClose={handleModalTriggerClick(setFaeModalOpen, false)}
        frozenAssetEntity={selectedFae}
        suspect
      />
      <ModalUploadClients
        open={clientModalOpen}
        onClose={handleModalTriggerClick(setClientModalOpen, false)}
      />
    </>
  )
}

export default ClientsListLayout

const Summary = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}))

const Table = styled(BaseTable)(({ theme }) => ({
  '.MuiDataGrid-row': {
    '.MuiDataGrid-cell--last-check, .MuiDataGrid-cell--date-of-birth': {
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
    },
  },
  '.MuiDataGrid-row--detailPanelExpanded': {
    '.MuiDataGrid-cell--last-check, .MuiDataGrid-cell--date-of-birth': {
      color: theme.palette.text.primary,
    },
  },
}))
