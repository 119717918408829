import { History } from 'history'
import {
  FileTextIcon,
  HistoryIcon,
  SearchIcon,
  SettingsIcon,
  UsersIcon,
  LifeBuoyIcon,
  HomeIcon,
} from 'icons'
import {
  FROZEN_ASSETS_ENTITIES_CLIENTS_URL,
  FROZEN_ASSETS_ENTITIES_SEARCH_URL,
  LOGIN_URL, REPORTING_ACE,
  REPORTING_ANALYSIS_URL,
  REPORTING_CLIENT_ANALYSIS_DATA_URL,
  REPORTING_CONFIGURATION_URL,
  REPORTING_HISTORY_URL,
} from 'utils/router/constants'

import { getLogout } from './Menu.service'

import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

type ELocation = 'reportings' | 'asset-freeze' | 'preferences'

export const logout = async (history: History) => {
  await getLogout()

  history.push(LOGIN_URL)
}

export const getLocationTitle = (location: ELocation) => {
  switch (location) {
    case 'reportings':
      return 'ACE'
    case 'asset-freeze':
      return 'STASE'
    case 'preferences':
      return null
  }
}

export const getLocationSubtitle = (location: ELocation) => {
  switch (location) {
    case 'reportings':
      return '<strong>A</strong>nalyse des <strong>C</strong>ommissions <strong>E</strong>x-post'
    case 'asset-freeze':
      return 'Contrôle continu du gel des avoirs'
    case 'preferences':
      return ''
  }
}

interface INavItem {
  icon: React.ElementType,
  name: string,
  link: string,
  externalLink?: boolean,
  exact?: boolean
}

interface INav {
  label: string,
  fields: INavItem[],
}

export const getLocationNav = (location: ELocation): INav[] => {
  switch (location) {
    case 'reportings':
      return [
        {
          label: '',
          fields: [
            { icon: HomeIcon, name: 'Accueil', link: REPORTING_ACE, exact: true },
          ]
        },
        {
          label: 'Bordereaux',
          fields: [
            { icon: FileTextIcon, name: 'Import et analyse', link: REPORTING_ANALYSIS_URL },
            { icon: SettingsIcon, name: 'Paramétrage des produits', link: REPORTING_CONFIGURATION_URL },
            { icon: HistoryIcon, name: 'Historique des imports', link: REPORTING_HISTORY_URL },
          ]
        },
        {
          label: 'Analyse',
          fields: [
            { icon: UsersIcon, name: 'Mes clients', link: REPORTING_CLIENT_ANALYSIS_DATA_URL }
          ]
        },
        {
          label: 'Aide',
          fields: [
            { icon: LifeBuoyIcon, name: 'Centre d’aide', link: 'https://busy-stop-fcc.notion.site/Guide-utilisateur-de-Sendraise-ACE-Agr-gateur-de-Commissions-Ex-post-643feb7f32644646b699897915a7c9c6?pvs=4', externalLink: true },
          ]
        }
      ]
    case 'asset-freeze':
      return [
        {
          label: "",
          fields: [
            { icon: UsersIcon, name: 'Liste des clients', link: FROZEN_ASSETS_ENTITIES_CLIENTS_URL },
            {
              icon: SearchIcon,
              name: 'Recherche de prospects',
              link: FROZEN_ASSETS_ENTITIES_SEARCH_URL,
            }
          ]
        }
      ]

    case 'preferences':
      return []
  }
}

export const getHideableElementSx = ({ theme, open }: { theme: Theme; open: boolean }): SxProps => {
  return {
    whiteSpace: 'nowrap',
    opacity: open ? 1 : 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeOut,
    }),
  }
}
