import { Box, Button, Link, Typography } from '@mui/material'
import React from 'react'
import { Stack } from '@mui/system'
import { LifeBuoyIcon, ExternalLinkIcon } from '../../../../../icons'

interface IGuideButtonProps {
  label: string,
  link: string
}

const GUIDES_BUTTONS: IGuideButtonProps[] = [
  {
    label: "Guide de récupération des bordereaux",
    link: "https://busy-stop-fcc.notion.site/2e20ec84baf44396aa0688adeb265107?v=b4a445010e8e441b89559a0f5f0aa6a2&pvs=4",
  },
  {
    label: "Types de commissions supportés",
    link: "https://busy-stop-fcc.notion.site/Types-de-commissions-support-s-par-assureur-30afc46f176d411692bc0df6810528f3",
  },
  {
    label: "Exporter ma base de contrats d’O2S",
    link: "https://busy-stop-fcc.notion.site/Guide-pas-pas-de-la-requ-te-comptes-O2S-011d0703ca6447c6afb46b3fcddf9d1d?pvs=4",
  }
]

function Guides(): React.ReactElement {
  return (
    <Box mt={'40px'}>
      <Stack direction="row" alignItems="center" gap={1}>
        <LifeBuoyIcon sx={{
          fontSize: 30,
          color: 'inherit',
        }} />
        <Typography variant="h2">Consultez nos guides</Typography>
      </Stack>
      <Stack mt={3} direction="row" alignItems="center" gap={2}>
        {GUIDES_BUTTONS.map((button, index) => (
          <Button
            key={index}
            size="large"
            href={button.link}
            target="_blank"
            component={Link}
            endIcon={<ExternalLinkIcon />}
          >
            {button.label}
          </Button>
        ))}
      </Stack>
      <Stack mt={2}>
        <Typography variant="body2" color="text.primary">
          Une question, une remarque, un dysfonctionnement ? Le support est là pour vous répondre : envoyez un mail à <a href="mailto:support@sendraise.eu">support@sendraise.eu</a>.
        </Typography>
      </Stack>
    </Box>
  )
}

export default Guides