import { Stack } from '@mui/system'
import { Box, MenuItem, Select, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import React, { useMemo } from 'react'
import { formatCurrencySymbol } from '../../../../../utils'

interface ISummary {
  availableYears: number[] | undefined,
  year: number | undefined,
  aggregated: {
    run: number | string,
    operations: number | string,
    totalAmount: number | string
  } | undefined,
  handleFilter: (e: any) => void
}

function Summary(props: ISummary): React.ReactElement {
  const {availableYears, year, aggregated, handleFilter} = props

  const useFormattedValue = (value: number | string | undefined): string => {
    return useMemo(() => formatCurrencySymbol(value ? value : '', 'EUR'), [value]);
  };

  const totalAmount = useFormattedValue(aggregated?.totalAmount);
  const run = useFormattedValue(aggregated?.run);
  const operations = useFormattedValue(aggregated?.operations);

  return (
    <Box bgcolor="background.inverted" p={'32px'} mt={'40px'} borderRadius={'4px'} color="primary.contrastText">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="buttonLarge">
          Commissions perçues en
        </Typography>
        <Select
          value={year ?? ''}
          defaultValue={year ?? ''}
          displayEmpty
          label="Année"
          onChange={(e) => handleFilter(e)}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(2px)',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'white',
            color: 'white',
            fontSize: '18px'
          }}
        >
          {availableYears?.map((y, index) => (
            <MenuItem value={y} key={index} sx={{fontSize: '14px'}}>{y}</MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack mt={'20px'} direction="row" alignItems="end">
        <Typography  variant="h1" color="primary.contrastText" fontWeight={700}>{totalAmount}</Typography>
        <Stack direction="row" gap={'40px'} alignItems="end" ml="auto">
          <Stack direction="column" gap={0.5}>
            <Typography  variant="body2_m" sx={(theme) => ({
              color: alpha(theme.palette.secondary.contrastText, 0.7),
            })}
            >
              Run
            </Typography>
            <Typography variant="body1" color="primary.contrastText">{run}</Typography>
          </Stack>
          <Typography variant="body1" color="primary.contrastText">+</Typography>
          <Stack direction="column"  gap={0.5}>
            <Typography  variant="body2_m" sx={(theme) => ({
              color: alpha(theme.palette.secondary.contrastText, 0.7),
            })}
            >
              Opérations
            </Typography>
            <Typography variant="body1" color="primary.contrastText">{operations}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default Summary