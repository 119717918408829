import * as React from 'react'

import { Box, Divider, Stack, Typography, styled } from '@mui/material'

interface IProps {
  crmId: string | null
  clientType: string | null
  maidenName: string | null
  placeOfBirth: string | null
  countryOfBirth: string | null
  nationality: string | null
  otherNationality: string | null
  occupation: string | null
  address: string | null
}

const ClientDetail: React.FC<IProps> = (props) => {
  const {
    crmId,
    clientType,
    maidenName,
    placeOfBirth,
    countryOfBirth,
    nationality,
    otherNationality,
    occupation,
    address,
  } = props

  return (
    <Wrapper>
      <Stack flexDirection="row">
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            id crm
          </Typography>
          <Typography variant="subtitle1">{crmId || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            type
          </Typography>
          <Typography variant="subtitle1">{clientType || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            lieu de naissance
          </Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {[placeOfBirth, countryOfBirth].filter((n) => n !== null && n !== undefined && n !== '').join(', ') || '-'}
          </Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            nationalité
          </Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {nationality || '-'}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <Stack flexDirection="row">
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            nom de naissance
          </Typography>
          <Typography variant="subtitle1">{maidenName || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            seconde nationalité
          </Typography>
          <Typography variant="subtitle1">{otherNationality || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            profession
          </Typography>
          <Typography variant="subtitle1">{occupation || '-'}</Typography>
        </Box>
        <Box flex={1} sx={{ p: 2 }}>
          <Typography
            variant="sectionHeading"
            component="p"
            color="text.secondary"
            mb={0.5}
          >
            adresse
          </Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {address || '-'}
          </Typography>
        </Box>
      </Stack>
    </Wrapper>
  )
}

export default ClientDetail

const Wrapper = styled(Stack)(({ theme }) => ({
  borderRadius: 2,
  background: theme.palette.background.default,
  flexGrow: 1,
}))
